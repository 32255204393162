html,
body {
  margin: 0;
  height: 100%;
}
#root {
  height: 100%;
  width: 100%;
}

.container {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.rotation {
  animation: App-logo-spin infinite 4s linear;
}

.App-content {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Unica77";
  font-weight: 100;
  src: local("Unica77"),
    url(./fonts/Unica77/Unica77LL-Thin.otf) format("opentype");
}
@font-face {
  font-family: "Unica77";
  font-weight: 300;
  src: local("Unica77"),
    url(./fonts/Unica77/Unica77LL-Light.otf) format("opentype");
}
@font-face {
  font-family: "Unica77";
  font-weight: 400;
  src: local("Unica77"),
    url(./fonts/Unica77/Unica77LL-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Unica77";
  font-weight: 500;
  src: local("Unica77"),
    url(./fonts/Unica77/Unica77LL-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Unica77";
  font-weight: 700;
  src: local("Unica77"),
    url(./fonts/Unica77/Unica77LL-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Unica77";
  font-weight: 900;
  src: local("Unica77"),
    url(./fonts/Unica77/Unica77LL-Black.otf) format("opentype");
}

@font-face {
  font-family: "Unica77";
  font-weight: 900;
  src: local("Unica77"),
    url(./fonts/Unica77/Unica77LL-ExtraBlack.otf) format("opentype");
}

.Toastify__toast-container > div {
  color: #374151;
  font-family: Unica77;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.07px;
}

.Toastify__toast--error
  > .Toastify__progress-bar--wrp
  > .Toastify__progress-bar {
  opacity: 1 !important;
  background: #ff3a30 !important;
}
.Toastify__toast-body
  > .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter
  svg
  path {
  fill: #ff3a30;
}

.Toastify__toast--success
  > .Toastify__progress-bar--wrp
  > .Toastify__progress-bar {
  opacity: 1 !important;
  background: #1ba904 !important;
}

.Toastify__toast--success
  > .Toastify__toast-body
  > .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter
  svg
  path {
  fill: #1ba904;
}
